import type { Session, SessionUser } from 'next-auth'

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const nuxtApp = useNuxtApp()
  const { t } = nuxtApp.$i18n

  const loadSession = nuxtApp.$loadSession as () => Promise<Session>
  const user = nuxtApp.$user as ComputedRef<SessionUser>
  const isUserLoading = nuxtApp.$isUserLoading as ComputedRef<boolean>

  if (isUserLoading.value || !user.value) {
    await loadSession()
  }

  const requiredRole = to.meta.role as string
  
  if (!user.value || (requiredRole && !user.value?.hasRole(requiredRole))) {
    const redirectPath = btoa(to.fullPath)
    return navigateTo(`/${t('login_path')}?redirect=${redirectPath}`)
  }
})